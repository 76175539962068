// Fenstergröße
$minWidthWindow: 1024px;
$minHeightWindow: 768px;

// Disabled
$disabled-opacity-value: 0.38;

// Farben
$background-page: #f5f8fa;
$background-content: white;
$background-contrast: #f0f0f0;

$primary-color: #005476;
$primary-color-disabled: rgba(0, 84, 118, 0.356);
$secondary-color: #a3c74f;
$secondary-color-disabled: rgba(163, 199, 79, 0.356);
$warn-color: #f44336;

$divider-color: lightgrey;
$precolon-color: rgba(128, 128, 128, 0.7);
// Keine transparenz, damit man bei verschiedenen Hintergründen die gleiche Farbe hat
$tile-hover-color: rgb(245, 245, 245);
$profile-hover-color: rgba(200, 200, 200, 0.2);
$font-color-disabled: rgba(117, 117, 117, 0.473);

$avatar-background-color: #d3d3d3;
$placeholder-icon-selected-color: white;

// Abstände
$space1: 2px;
$space2: 4px;
$space3: 8px;
$space4: 16px;
$space5: 32px;

// Schriftgrößen
$font-size1: 12px;
$font-size2: 14px;
$font-size3: 16px;
$font-size4: 18px;
$font-size5: 20px;
$font-size6: 22px;
$font-size7: 24px;

// Icon-Größen
$icon-size: 24px; // Standardgröße
$icon-size1: 18px;
$icon-size2: 64px;
$icon-size3: 128px;

// Avatar Größen
$avatar-size1: 18px;
$avatar-size2: 56px;
$avatar-size3: 64px;
$avatar-size4: 128px;
