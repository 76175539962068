@import 'src/styles/constants.scss';

html {
  overflow: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  min-width: $minWidthWindow;
  min-height: $minHeightWindow;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// Damit Dialoge bei zu kleinen Fenstern mit der gesamten Maske gescrollt werden
.cdk-global-scrollblock {
  position: initial !important;
}
// Für das Scrolling bei zu kleinen Fenstern und geöffnetem Dialog
.cdk-overlay-container {
  position: absolute !important;
  min-width: $minWidthWindow;
  min-height: $minHeightWindow;
}

// Ohne ist die kachelansicht aus dem wrapper gelaufen aber erst seit dem umbau
// der generic list. an welcher aenderung es lag konnte nicht festgestellt werden.
.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

input {
  outline-color: $primary-color;
}

.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: unset !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: unset !important;
}

.mat-mdc-card {
  padding: 16px;
}

body div.mat-mdc-select-panel {
  padding: 0;
}

body .mat-mdc-form-field > .mdc-text-field {
  padding: 0;
}

body .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
  min-height: 44px;
}

.page-container {
  height: 100%;
  padding: $space4;
  background-color: $background-page;
}

.clickable {
  cursor: pointer;
  user-select: none;
  outline: none;
}

.readonly {
  cursor: default !important;
  user-select: none;
  outline: none;
  background: transparent !important;
}

.mirrorX {
  transform: scaleX(-1);
}

.mirrorY {
  transform: scaleY(-1);
}

.italic {
  font-style: italic;
}

// Klassen für die Größendarstellung von Icons
.icon-1 {
  font-size: $icon-size1 !important;
  width: $icon-size1 !important;
  height: $icon-size1 !important;
}

.icon-2 {
  font-size: $icon-size2 !important;
  width: $icon-size2 !important;
  height: $icon-size2 !important;

  svg {
    width: $icon-size2 !important;
    height: $icon-size2 !important;
  }
}

.icon-3 {
  font-size: $icon-size3 !important;
  width: $icon-size3 !important;
  height: $icon-size3 !important;
  padding: 0 !important;

  svg {
    width: $icon-size3 !important;
    height: $icon-size3 !important;
  }
}

// Klassen für die Größendarstellung von Avataren
.avatar-1,
.avatar-2,
.avatar-3,
.avatar-4 {
  border-radius: 50%;
}

.avatar-1 {
  width: $avatar-size1 !important;
  height: $avatar-size1 !important;
}

.avatar-2 {
  width: $avatar-size2 !important;
  height: $avatar-size2 !important;
}

.avatar-3 {
  width: $avatar-size3 !important;
  height: $avatar-size3 !important;
}

.avatar-4 {
  width: $avatar-size4 !important;
  height: $avatar-size4 !important;
}

img.user-av,
img.group-av {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

// Anpassungen an material Buttons
.mdc-button {
  letter-spacing: normal !important;
}

body .mat-mdc-unelevated-button.mat-primary,
body .mat-mdc-unelevated-button.mat-accent,
body .mat-mdc-unelevated-button.mat-warn,
body .mat-mdc-raised-button.mat-primary,
body .mat-mdc-raised-button.mat-accent,
body .mat-mdc-raised-button.mat-warn {
  color: #fff;
}

body .mat-mdc-unelevated-button.mat-primary[disabled],
body .mat-mdc-unelevated-button.mat-accent[disabled],
body .mat-mdc-unelevated-button.mat-warn[disabled],
body .mat-mdc-raised-button.mat-primary[disabled],
body .mat-mdc-raised-button.mat-accent[disabled],
body .mat-mdc-raised-button.mat-warn[disabled] {
  color: #00000042;
}

body .mat-mdc-unelevated-button > .mat-icon,
body .mat-mdc-raised-button > .mat-icon,
body .mat-mdc-outlined-button > .mat-icon {
  margin-left: 2px !important;
  margin-right: 2px !important;
  height: 24px;
  width: 24px;
}

body .mat-mdc-icon-button.mat-mdc-button-base {
  height: 40px;
  width: 40px;
  padding: 8px;
}

.mat-mdc-unelevated-button {
  width: 250px;
  height: 36px;
}

.mat-mdc-outlined-button {
  background-color: $background-content !important;
  height: 36px;
}

.mat-mdc-outlined-button.mat-primary {
  border-color: $primary-color !important;
}

.mat-mdc-outlined-button.mat-accent {
  border-color: $secondary-color !important;
}

.mat-mdc-outlined-button.mat-warn {
  border-color: $warn-color !important;
}

.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  margin-right: $space3 !important;
}

// Anpassungen am Material Menü Panel für die Suchleiste
.mat-mdc-menu-panel {
  padding: 0;
  min-height: 48px !important;
  max-width: 300px !important;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

body .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: #a3c74f;
  --mdc-switch-selected-track-color: #a3c74f8a;
  --mdc-switch-selected-focus-track-color: #a3c74f8a;
}

.mdc-switch__icons {
  display: none;
}

// Anpassungen für die Material Icons
.mat-icon {
  user-select: none;
}

// Anpassungen am Stepper
ca-dialog {
  height: 100%;
}

.mat-mdc-dialog-surface {
  background-color: $background-page !important;
  padding: 24px;

  .mat-stepper-horizontal {
    $header-height: 90px;
    $stepper-height: $header-height + $space1 + $space3;

    background-color: $background-page;

    .mat-horizontal-stepper-header-container {
      height: $header-height;
      margin-top: $space1;
      margin-bottom: $space3;

      .mat-step-header {
        height: $header-height;
      }

      // Abgeschlossene Steps im Assistenten in grün darstellen
      .mat-step-icon-state-edit {
        background-color: $secondary-color;
      }
    }

    // Das CSS vom Stepper ist nur fuer eine dynamische Content Hoehe ausgelegt
    // wir brauchen aber eine fixe Hoehe und die noch ausgeblendeten Steps muessen
    // auch schon die richtige Hoehe haben damit der VirtualScrollViewport richtig
    // initialisiert. Um alle Steps also auf 100% zu setzten kommen wir um eine absolute
    // Positionierung nicht drum herum.
    .mat-horizontal-content-container {
      position: relative;
      height: calc(100% - #{$stepper-height});
      min-height: calc(100% - #{$stepper-height});
      padding: 0px;

      .mat-horizontal-stepper-content {
        position: absolute;
        height: 100%;
        width: 100%;

        // Das CSS wird sowieso vom Stepper gesetzt. Wenn dynamisch per ngIf ein
        // Step eingeblendet wird, wird es aber erst zu spät gesetzt und der step
        // wird kurz angezeigt und fliegt dann aus dem bild.
        transform: translate3d(100%, 0px, 0px);
        visibility: hidden;
      }
    }

    .mat-horizontal-stepper-wrapper {
      height: 100%;
    }
  }
}

.mdc-dialog .mdc-dialog__content {
  margin-left: -24px;
  margin-right: -24px;
}

// Anpassung an Listen
.mdc-list-item.mdc-list-item--with-one-line {
  height: 56px !important;
}

.mat-mdc-list-base .mat-mdc-subheader {
  height: 56px !important;
}

.mat-mdc-list-base .mat-mdc-subheader:first-child {
  margin-top: 0 !important;
}

// Headline Anpassungen
h1,
h4 {
  color: $primary-color;
  text-align: center;
}

// Allgemeine Margin-Klassen

// Ebene 1
.mg-1 {
  margin: $space1 !important;
}

.mg-1-top {
  margin-top: $space1 !important;
}

.mg-1-right {
  margin-right: $space1 !important;
}

.mg-1-bottom {
  margin-bottom: $space1 !important;
}

.mg-1-left {
  margin-left: $space1 !important;
}

.mg-1-vert {
  margin-top: $space1 !important;
  margin-bottom: $space1 !important;
}

.mg-1-horz {
  margin-left: $space1 !important;
  margin-right: $space1 !important;
}

// Ebene 2
.mg-2 {
  margin: $space2 !important;
}

.mg-2-top {
  margin-top: $space2 !important;
}

.mg-2-right {
  margin-right: $space2 !important;
}

.mg-2-bottom {
  margin-bottom: $space2 !important;
}

.mg-2-left {
  margin-left: $space2 !important;
}

.mg-2-vert {
  margin-top: $space2 !important;
  margin-bottom: $space2 !important;
}

.mg-2-horz {
  margin-left: $space2 !important;
  margin-right: $space2 !important;
}

// Ebene 3
.mg-3 {
  margin: $space3 !important;
}

.mg-3-top {
  margin-top: $space3 !important;
}

.mg-3-right {
  margin-right: $space3 !important;
}

.mg-3-bottom {
  margin-bottom: $space3 !important;
}

.mg-3-left {
  margin-left: $space3 !important;
}

.mg-3-vert {
  margin-top: $space3 !important;
  margin-bottom: $space3 !important;
}

.mg-3-horz {
  margin-left: $space3 !important;
  margin-right: $space3 !important;
}

// Ebene 4
.mg-4 {
  margin: $space4 !important;
}

.mg-4-top {
  margin-top: $space4 !important;
}

.mg-4-right {
  margin-right: $space4 !important;
}

.mg-4-bottom {
  margin-bottom: $space4 !important;
}

.mg-4-left {
  margin-left: $space4 !important;
}

.mg-4-vert {
  margin-top: $space4 !important;
  margin-bottom: $space4 !important;
}

.mg-4-horz {
  margin-left: $space4 !important;
  margin-right: $space4 !important;
}

// Ebene 5
.mg-5 {
  margin: $space5 !important;
}

.mg-5-top {
  margin-top: $space5 !important;
}

.mg-5-right {
  margin-right: $space5 !important;
}

.mg-5-bottom {
  margin-bottom: $space5 !important;
}

.mg-5-left {
  margin-left: $space5 !important;
}

.mg-5-vert {
  margin-top: $space5 !important;
  margin-bottom: $space5 !important;
}

.mg-5-horz {
  margin-left: $space5 !important;
  margin-right: $space5 !important;
}

// Allgemeine Padding-Klassen

// Ebene 1
.pad-1 {
  padding: $space1 !important;
}

.pad-1-top {
  padding-top: $space1 !important;
}

.pad-1-right {
  padding-right: $space1 !important;
}

.pad-1-bottom {
  padding-bottom: $space1 !important;
}

.pad-1-left {
  padding-left: $space1 !important;
}

.pad-1-vert {
  padding-top: $space1 !important;
  padding-bottom: $space1 !important;
}

.pad-1-horz {
  padding-left: $space1 !important;
  padding-right: $space1 !important;
}

// Ebene 2
.pad-2 {
  padding: $space2 !important;
}

.pad-2-top {
  padding-top: $space2 !important;
}

.pad-2-right {
  padding-right: $space2 !important;
}

.pad-2-bottom {
  padding-bottom: $space2 !important;
}

.pad-2-left {
  padding-left: $space2 !important;
}

.pad-2-vert {
  padding-top: $space2 !important;
  padding-bottom: $space2 !important;
}

.pad-2-horz {
  padding-left: $space2 !important;
  padding-right: $space2 !important;
}

// Ebene 3
.pad-3 {
  padding: $space3 !important;
}

.pad-3-top {
  padding-top: $space3 !important;
}

.pad-3-right {
  padding-right: $space3 !important;
}

.pad-3-bottom {
  padding-bottom: $space3 !important;
}

.pad-3-left {
  padding-left: $space3 !important;
}

.pad-3-vert {
  padding-top: $space3 !important;
  padding-bottom: $space3 !important;
}

.pad-3-horz {
  padding-left: $space3 !important;
  padding-right: $space3 !important;
}

// Ebene 4
.pad-4 {
  padding: $space4 !important;
}

.pad-4-top {
  padding-top: $space4 !important;
}

.pad-4-right {
  padding-right: $space4 !important;
}

.pad-4-bottom {
  padding-bottom: $space4 !important;
}

.pad-4-left {
  padding-left: $space4 !important;
}

.pad-4-vert {
  padding-top: $space4 !important;
  padding-bottom: $space4 !important;
}

.pad-4-horz {
  padding-left: $space4 !important;
  padding-right: $space4 !important;
}

// Ebene 5
.pad-5 {
  padding: $space5 !important;
}

.pad-5-top {
  padding-top: $space5 !important;
}

.pad-5-right {
  padding-right: $space5 !important;
}

.pad-5-bottom {
  padding-bottom: $space5 !important;
}

.pad-5-left {
  padding-left: $space5 !important;
}

.pad-5-vert {
  padding-top: $space5 !important;
  padding-bottom: $space5 !important;
}

.pad-5-horz {
  padding-left: $space5 !important;
  padding-right: $space5 !important;
}
