@import 'src/styles/constants.scss';

.page-header {
  font-size: $font-size3;
  color: $precolon-color;
  margin-bottom: $space3;
}

.list-desc {
  color: $primary-color;
  font-size: $font-size5;
  text-align: center;
  margin-bottom: $space4 + $space3;
  margin-top: $space3;
  height: $space5;
}

.list-elem {
  font-size: $font-size2;
}

.tile-title-wrapper {
  overflow: hidden;
}

.tile-title {
  font-size: $font-size6;
  color: $primary-color;
  max-height: 55px;
  // overflow: hidden;
  // display: -webkit-box !important;
  // -webkit-line-clamp: 2 !important;
  // -webkit-box-orient: vertical !important;
  // word-wrap: break-word;
  // word-break: break-all;
}

.tile-title-subtitle {
  font-size: $font-size4;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.break-word {
  word-break: break-word;
}

.tile-subtitle {
  font-size: $font-size3;
  font-weight: normal;
  color: $secondary-color;
}

.tile-counter-big,
.tile-counter-small {
  font-weight: 600;
  color: $secondary-color;
}

.tile-counter-big {
  font-size: $font-size6;
}

.tile-counter-small {
  font-size: $font-size4;
}

.tile-counter-lbl-big,
.tile-counter-lbl-small {
  color: $primary-color;
}

.tile-counter-lbl-big {
  font-size: $font-size4;
}

.tile-counter-lbl-small {
  font-size: $font-size3;
}

.bold {
  font-weight: bold;
}

.bold-500 {
  font-weight: 500;
}

.row-subtitle {
  font-size: $font-size1;
  line-height: 14px;
  max-height: 28px;
  overflow: hidden;
}
